import React from "react"
import moment from "moment"
import DynamicComponent from "../DynamicComponent/index"
import Share from "../Share/index"
import ImagesService from '../../utils/images-service'
import SbEditable from "storyblok-react"

import "./styles.scss"
import "moment/min/locales"

import { withTranslation } from "react-i18next"

const News = ({ blok, title, date, language, slug, t }) => {
  const content =
    blok.body &&
    blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)

  moment.locale(language)
  
  return (
      
      <div className="news-article">
        <div className="hero-block-splitscreen">
          <div className="container">
            <div className="text">
              <div className="h3">{blok.subtitle}</div>
              <h1>{title}</h1>
              <p>{blok.teaser}</p>
              <div className="date-share">
                <div className="publishing-date">
                  <time pubdate="pubdate" dateTime={moment(date).format('YYYY-MM-DDTHH:mm:ssZ')}>{moment(date).format('Do MMMM YYYY')}</time>
                </div>
                <Share link={`https://www.gerald-asamoah-stiftung.de/${slug}`} headline={title} label={t("news.share.label")} title={t("news.share.title")} />
              </div>
            </div>
            {blok.image.filename !== "" &&
              <div className="image" style={{backgroundImage: `url(${ ImagesService(blok.image.filename, true, { path: '900x1400/smart'}) })`}}>
                <img src={ImagesService(blok.image.filename, true, { path: '450x338/smart'})} srcSet={`${ImagesService(blok.image.filename, true, { path: '450x338/smart'})} 1x, ${ImagesService(blok.image.filename, true, { path: '900x676/smart'})} 2x`} width="450" height="338" />
              </div>
            }
          </div>
        </div>
          <article className="news-body">
            <div className="container">
              <div className="news-body">
                <SbEditable content={blok}>
                  { content }
                </SbEditable>
              </div>
            </div>
          </article>
      </div>
  )
}

export default withTranslation()(News)