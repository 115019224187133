import React, { Component } from "react"
import Button from "../Button/index"
import Modal from "../Modal/index"
import ExternalShare from "./External/index"

import "./styles.scss"

import { withTranslation } from "react-i18next"

class Share extends Component {
    constructor(props) {
        super(props)
        this.link = props.link
        this.label = props.label
        this.title = props.title
        this.headline = props.headline
        this.t = props.t
        this.copyRef= React.createRef()

        this.state = {
            open: false
        }

        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.copyToClipboard = this.copyToClipboard.bind(this)
    }

    async componentDidMount() {
        this.setState({ open: false })
    }

    async openModal() {
        if (navigator.canShare) {
            try {
                await navigator.share({
                    url: this.link
                })
            } catch(error) {}
        } else {
            this.setState({ open: true })
        }
    }

    closeModal() {
        this.setState({ open: false })
    }

    copyToClipboard() {
        this.copyRef.current.select()
        document.execCommand("copy")
    }

    printPage() {
        window.print()
    }

    render() {
        return (
            <div className="share">
                <div className="button-wrapper">
                    <Button type="tertiary" to="#" action={this.openModal} title={this.title} label={this.label} /> 
                </div>
                {this.state.open && 
                <Modal closeCallback={this.closeModal} headline={this.t("share.headline")}>
                    <div className="share-modal">
                        <div className="share-url">
                            <input ref={this.copyRef} readOnly="readonly" type="text" value={this.link} />
                            {document.queryCommandSupported('copy') &&
                                <Button type="tertiary" to="#" action={this.copyToClipboard} title="Copy to clipboard" label="" icon="content_copy" />
                            }
                        </div>
                        <ul>
                            <ExternalShare url={`https://www.linkedin.com/shareArticle?mini=true&url=${ encodeURI(this.link) }&title=${ encodeURI(this.headline) }`} width="500" height="500" type="LinkedIn" title={this.t("share.providers.linkedin.title")} />
                            <ExternalShare url={`https://twitter.com/intent/tweet?url=${ encodeURI(this.link) }&via=conducolabs&text=${ encodeURI(this.headline) }`} width="500" height="500" type="Twitter" title={this.t("share.providers.twitter.title")} />
                            <ExternalShare url={`https://www.facebook.com/dialog/share?app_id=815996722311997&display=popup&href=${ encodeURI(this.link) }`} width="500" height="500" type="Facebook" title={this.t("share.providers.facebook.title")} />
                            <ExternalShare url={`https://www.xing.com/spi/shares/new?url=${ encodeURI(this.link) }`} width="500" height="500" type="XING" title={this.t("share.providers.xing.title")} />
                            <li><a className="social-icon mail-link" href={`mailto:?body=${ encodeURI(this.link) }`} title={this.t("share.providers.mail.title")}>{this.t("share.providers.xing.label")}</a></li>
                            <li><span className="social-icon print-link" onClick={this.printPage} role="button" onKeyDown={this.printPage} tabIndex={0} title={this.t("share.providers.print.title")}>{this.t("share.providers.print.label")}</span></li>
                        </ul>
                        <p className="legal-notice">{this.t("share.legalNotice")}</p>
                    </div>
                </Modal>
                }
            </div>
        )
    }
}

export default withTranslation()(Share)