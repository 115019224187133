import React, { Component } from "react"

class ExternalShare extends Component {
    constructor(props) {
        super(props)
        this.t = props.t
        this.url = props.url
        this.type = props.type
        this.width = props.width
        this.height = props.height
        this.title = props.title
        this.openExternalDialog = this.openExternalDialog.bind(this)
    }

    openExternalDialog() {
        window.open(this.url, `${ this.type.toLowerCase() }Window`, `toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${ this.width }, height=${ this.height }`)
        return false
    }

    render() {
        return (
            <li>
                <a className={`social-icon ${ this.type.toLowerCase() }-link`} href={this.url} onClick={this.openExternalDialog} target="_blank" rel="noreferrer" title={this.title}>{ this.type }</a>
            </li>
        )
    }
}  
  
export default ExternalShare